exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-css-rabbit-hole-js": () => import("./../../../src/pages/blog/css-rabbit-hole.js" /* webpackChunkName: "component---src-pages-blog-css-rabbit-hole-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interview-prep-js": () => import("./../../../src/pages/interview-prep.js" /* webpackChunkName: "component---src-pages-interview-prep-js" */),
  "component---src-pages-minesweeper-js": () => import("./../../../src/pages/minesweeper.js" /* webpackChunkName: "component---src-pages-minesweeper-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-sri-lang-js": () => import("./../../../src/pages/sri-lang.js" /* webpackChunkName: "component---src-pages-sri-lang-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

